import { createSlice } from '@reduxjs/toolkit'

//**INITIAL STATE */
const initialState = {
  tabValue: '1',
  searchValue: {
    serviceprovider: '',
    reseller: '',
    customer: '',
    referralagent: '',
    sipTrunk: '',
  },
  filteredRows: null,
  columnFilters: {
    status: false,
    primaryContact: false,
    registeredDate: false,
  },
  companyId: null,
  reduxCompanyDetails: null,
  menuItems: [],
  breadcrumbs: {
    master: '',
    serviceProvider: '',
    reseller: '',
    customer: '',
    directCustomer: '',
    referralAgent: '',
  },
  activeBreadCrumb: [],
  serviceProvidersList: null,
  resellersList: null,
  customersList: null,
  directCustomersList: null,
  altigenCustomers: null,
  orderDeploymentStatusList: '',

  isToggleCustomer: false,
  isToggleServiceProvider: false,
  isToggleReseller: false,
  isToggleDirectCustomer: false,
  isDeploymentStatusView: false,
  isDirectRoutingConfigurationAlert: false,
  isOrderActivatedView: false,
  customerTrigger: '',
  resellerTrigger: '',
  serviceProviderTrigger: '',

  companyLevelApproval: '',
  toggleReseller: true,
  toggleCustomer: false,
  alignment: 'reseller'
}

export const homeSlice = createSlice({
  name: 'homeSlice',
  initialState,
  reducers: {
    setTabValue: (state, { payload }) => {
      state.tabValue = payload
    },
    setSearchValue: (state, { payload }) => {
      state.searchValue = payload
    },
    setFilteredRows: (state, { payload }) => {
      state.filteredRows = payload
    },
    setColumnFilters: (state, { payload }) => {
      state.columnFilters = payload
    },
    setBreadcrumbs: (state, { payload }) => {
      state.breadcrumbs = payload
    },
    setActiveBreadcrumb: (state, { payload }) => {
      state.activeBreadCrumb = payload
    },
    setReduxCompanyDetails: (state, { payload }) => {
      state.reduxCompanyDetails = payload
    },
    setCompanyId: (state, { payload }) => {
      state.companyId = payload
    },
    setMenuItems: (state, { payload }) => {
      state.menuItems = payload
    },
    setServiceProvidersList: (state, { payload }) => {
      state.serviceProvidersList = payload
    },
    setResellersList: (state, { payload }) => {
      state.resellersList = payload
    },
    setCustomersList: (state, { payload }) => {
      state.customersList = payload
    },
    setAltigenCustomers: (state, { payload }) => {
      state.altigenCustomers = payload
    },
    setDirectCustomersList: (state, { payload }) => {
      state.directCustomersList = payload
    },
    setIsToggleCustomer: (state, { payload }) => {
      state.isToggleCustomer = payload
    },
    setIsToggleReseller: (state, { payload }) => {
      state.isToggleReseller = payload
    },
    setIsToggleServiceProvider: (state, { payload }) => {
      state.isToggleServiceProvider = payload
    },
    setIsToggleDirectCustomer: (state, { payload }) => {
      state.isToggleDirectCustomer = payload
    },
    setIsDeploymentStatusView: (state, { payload }) => {
      state.isDeploymentStatusView = payload
    },
    setIsorderDeploymentStatusList: (state, {payload}) => {
      state.orderDeploymentStatusList = payload
    },
    setIsOrderActivatedView: (state, { payload }) => {
      state.isOrderActivatedView = payload
    },
    setIsDirectRoutingConfigurationAlert: (state, { payload }) => {
      state.isDirectRoutingConfigurationAlert = payload
    },
    setCustomerTrigger: (state, { payload }) => {
      state.customerTrigger = payload
    },
    setResellerTrigger: (state, { payload }) => {
      state.resellerTrigger = payload
    },
    setServiceProviderTrigger: (state, { payload }) => {
      state.serviceProviderTrigger = payload
    },
    setCompanyLevelApproval: (state, { payload }) => {
      state.companyLevelApproval = payload
    },
    setToggleReseller: (state, { payload }) => {
      state.toggleReseller = payload
    },
    setToggleCustomer: (state, { payload }) => {
      state.toggleCustomer = payload
    },
    setAlignment: (state, { payload }) => {
      state.alignment = payload
    },
  },
})

// export state as function
export const homeSliceState = (state) => state.homeSlice
// export actions

export const {
  setTabValue,
  setSearchValue,
  setCompanyId,
  setBreadcrumbs,
  setActiveBreadcrumb,
  setReduxCompanyDetails,
  setFilteredRows,
  setColumnFilters,
  setMenuItems,
  setServiceProvidersList,
  setCustomersList,
  setDirectCustomersList,
  setAltigenCustomers,
  setResellersList,
  setIsToggleCustomer,
  setIsToggleReseller,
  setIsToggleServiceProvider,
  setIsToggleDirectCustomer,
  setIsDeploymentStatusView,
  setIsorderDeploymentStatusList,
  setIsDirectRoutingConfigurationAlert,
  setIsOrderActivatedView,
  setCustomerTrigger,
  setResellerTrigger,
  setServiceProviderTrigger,
  setCompanyLevelApproval,
  setToggleReseller,
  setToggleCustomer,
  setAlignment
} = homeSlice.actions

export default homeSlice.reducer
