import { createSlice } from '@reduxjs/toolkit'
import { initial } from 'lodash'

const initialState = {
  accountDetail: {
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
    extNumber: '',
  },
  companyDetail: {
    companyName: '',
    street: '',
    streetNumber: '',
    country: 'USA',
    city: '',
    state: '',
    zip: '',
    phone: '',
    fax: '',
  },
  billing: {
    email: '',
    firstName: '',
    lastName: '',
    mobile: '',
    fax: '',
    phone: '',
    phoneExtension: '',
    companyName: '',
    country: 'USA',
    state: '',
    city: '',
    zip: '',
    line1: '',
    line2: '',
  },

  creditCard: {
    firstName: '',
    lastName: '',
    cardNumber: '',
    expriationMonth: 0,
    expirationYear: 0,
    cvv: '',
  },
  bankAccount: {
    accountName: '',
    accountNumber: '',
    routingNumber: '',
    accountType: '',
    bankName: '',
    checkNumber: '',
  },
  appearance: {
    logoUrl: '',
  },
  billingType: {
    billingtype: 1,
  },
  sipDetails: {},
  step: 0,
  isRevParent: false,
  altigenDirectCustomer: false,
  isAltigenBilling: false,
  companyLevel: '',
  directCustomer: false,
  paymentType: 'card',
  service: null,
  termsAndConditionBase64String: '',
  isTermsAndConditionsAccepted: false,
  sipSignupAddressId: 0,
}

export const stepperSlice = createSlice({
  name: 'stepperSlice',
  initialState,
  reducers: {
    accountDetailsAction: (state, { payload }) => {
      (state.accountDetail.firstName = payload.firstName),
        (state.accountDetail.lastName = payload.lastName),
        (state.accountDetail.phoneNumber = payload.phoneNumber),
        (state.accountDetail.ext = payload.ext)
    },
    // setting account details data
    setAccountDetails: (state, action) => {
      state.accountDetail = action.payload
    },
    setEmail: (state, action) => {
      state.accountDetail = { ...state.accountDetail, email: action.payload }
    },
    setcompanyName: (state, action) => {
      state.companyDetail = {
        ...state.companyDetail,
        companyName: action.payload,
      }
    },
    // setting company details data
    setCompanyDetailsAction: (state, { payload }) => {
      state.companyDetail = payload
    },
    billingInfo: (state, { payload }) => {
      state.billing = payload
    },
    // setting billing info and address data
    setbillingInfo: (state, { payload }) => {
      state.billing = payload
    },
    creditCardAction: (state, { payload }) => {
      state.creditCard = payload
    },
    // setting credit card data
    setCreditCardAction: (state, { payload }) => {
      state.creditCard = payload
    },
    // setting bank account
    setBankAccount: (state, { payload }) => {
      state.bankAccount = payload
    },
    appearance: (state, { payload }) => {
      state.appearance.logoUrl = payload
    },
    setBillinType: (state, { payload }) => {
      state.billingType = {
        ...state.billingType,
        billingType: Number(payload.value),
      }
    },
    setRevParent: (state, action) => {
      state.isRevParent = action.payload
    },
    setAltigenCustomer: (state, action) => {
      state.altigenDirectCustomer = action.payload
    },
    setCompanyDraftAll: (state, { payload }) => {
      state.accountDetail = payload.accountDetail
      state.companyDetail = payload.companyDetail
      state.billingType = payload.billingType
      state.billing = payload.billing
      state.creditCard = payload.creditCard
      state.bankAccount = payload.bankAccount
      state.appearance = payload.appearance
      state.step = payload.step
      state.isRevParent = payload.isRevParent
      state.altigenDirectCustomer = payload.altigenDirectCustomer
      state.isAltigenBilling = payload.isAltigenBilling
      state.companyLevel = payload.companyLevel
      state.paymentType = payload.paymentType
      state.directCustomer = payload.directCustomer
    },
    setSIPDetails: (state, action) => {
      state.sipDetails = action.payload
    },
    setPaymentType: (state, action) => {
      state.paymentType = action.payload
    },
    setTermsAndConditionsBase64String: (state, action) => {
      state.termsAndConditionBase64String = action.payload
    },
    setService: (state, action) => {
      state.service = action.payload
    },
    setIsTermsAndConditionsAccepted: (state, action) => {
      state.isTermsAndConditionsAccepted = action.payload
    },
    setSignupAddressId: (state, action) => {
      state.sipSignupAddressId = action.payload
    },
    StepForward: (state, { payload }) => {
      if (payload) {
        state.step = state.step + Number(payload)
      } else {
        state.step = state.step + 1
      }
    },
    StepBackward: (state, { payload }) => {
      if (payload) {
        state.step = state.step - Number(payload)
      } else {
        state.step = state.step - 1
      }
    },
    resetBillingInfo: (state) => {
      state.billing = {
        ...state.billing,
        email: initialState.billing.email,
        firstName: initialState.billing.firstName,
        lastName: initialState.billing.lastName,
        phone: initialState.billing.phone,
        phoneExtension: initialState.billing.phoneExtension,
      }
    },
    resetBillingAddress: (state) => {
      state.billing = {
        ...state.billing,
        companyName: initialState.billing.companyName,
        city: initialState.billing.city,
        state: initialState.billing.state,
        country: initialState.billing.country,
        line1: initialState.billing.line1,
        line2: initialState.billing.line2,
        zip: initialState.billing.zip,
      }
    },
  },
})

// export state as function
export const formDetailsState = (state) => state.stepperSlice
// export actions

export const {
  accountDetailsAction,
  setAccountDetails,
  // step 2
  companyDetailsAction,
  setCompanyDetailsAction,
  // step 3
  billingInfo,
  setbillingInfo,
  // step 4
  creditCardAction,
  setCreditCardAction,
  // step 5
  bankAccountAction,
  setBankAccount,
  // step 6
  appearance,
  setBillinType,
  setResellerBillinType,
  setSIPDetails,
  setcompanyName,
  setEmail,
  setCompanyDraftAll,
  StepForward,
  StepBackward,
  setPaymentType,
  setRevParent,
  setAltigenCustomer,
  setService,
  setTermsAndConditionsBase64String,
  setIsTermsAndConditionsAccepted,
  setSignupAddressId,
  resetBillingInfo,
  resetBillingAddress,
} = stepperSlice.actions

// export reducers
export default stepperSlice.reducer
