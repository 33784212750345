import { useCallback, useEffect } from 'react';

import {
  Container,
  Typography,
  Button,
  Grid,
  Tooltip,
  IconButton,
} from '@mui/material';
import configAlert from '../../../../../assets/config-settings-alert.png';
import DirectRoutingConfigDialog from '../../direct-routing/DirectRoutingConfigDialog';
import { useState } from 'react'
import { serviceSliceState, setConfiguration } from '../../../../../store/slice/serviceSlice';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomerConfiguration } from '../../../../../api/get/getApi';
import { executeWithTryCatch, gettingCompanyId, isResponseSuccess, normalizeApiResponse } from '../../../../../helpers/HelperFunctions';
import { useToastContext } from '../../../../../App';
import { PRODUCT_NAMES } from '../../../../../helpers/Constants';
import CancelIcon from '@mui/icons-material/Cancel'

export default function OrderActivatedView(props) {
  const {toast} = useToastContext();
  const isActivated = localStorage.getItem('isAlreadyActivated')
  const [showConfig, setShowConfig] = useState(false);
  const state = useSelector(serviceSliceState)
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);


  const handleConfigChange = () => {
    setShowConfig(false);
  }

  useEffect(() => { 
    executeWithTryCatch(getConfigurationDetails, handleGetConfigError)
  }, [])
  const openDirectRoutingConfigDialog = async () => {
    setShowConfig(true);
    executeWithTryCatch(getConfigurationDetails, handleGetConfigError)
  }

 const getConfigurationDetails = async () => {
  setIsLoading(true)
    const response = await getCustomerConfiguration(gettingCompanyId());
    if (isResponseSuccess(response)) {
      dispatch(setConfiguration({
        ...response.data.result,
        userName : response.data.result.username,
        aadTenantId : response.data.result.aadTenantId
      }))
    }
    setIsLoading(false)
  }

  const handleGetConfigError = (error) => {
    setIsLoading(false)
    toast.showToast(normalizeApiResponse(error)?.message, 'error');
  }

  const renderConfigDialog = useCallback(() => {
    return (
      <DirectRoutingConfigDialog
        onClose={() => setShowConfig(false)}
        onChange={handleConfigChange}
        configData={state.configuration}
        isLoading={isLoading}
        goToServiceFunction={props?.goToServiceFunction}
      />
    );
  }, [state.configuration, isLoading]);

  return (
    <Grid item xs={12}>
              <div
                style={{ padding: '10px 0', borderBottom: '1px solid silver' }}
              >
                <Tooltip title="Close">
                  <IconButton
                    aria-label="back"
                    color="error"
                    size="large"
                    style={{
                      marginLeft: '2px',
                      marginTop: '-10px',
                      float: 'right',
                      borderRadius: 0,
                    }}
                    onClick={() => props?.goToServiceFunction('list')}
                  >
                    <CancelIcon color="error" size="large"></CancelIcon>
                  </IconButton>
                </Tooltip>
                <span className="services-header-font">
                  {' '}
                  <span style={{ fontWeight: '600' }}>{PRODUCT_NAMES.TPS}</span>
                </span>
              </div>
    <Container maxWidth="xl" maxHeight="xl" style={{ textAlign: 'center', padding: '20px' }}>
      <div style={{ marginTop: '5%' }}>
        <img
          src={configAlert}
          alt="Configuration Alert"
          style={{
            maxWidth: '200px',
            maxHeight: '200px',
          }}
        />
      </div>

      <Typography variant="h5" component="h5" style={{ margin: '20px 0' }}>
        {isActivated === 'true'
          ? 'Your order has already been activated. You can proceed with the deployment or manage your settings.'
          : 'Your order has been successfully activated for this service. To continue with the deployment, please provide your tenant ID by clicking the ‘Administrate’ button.'}
      </Typography>

      <div style={{ justifyContent: 'center', display: 'flex' }}>
        <Button variant="contained" onClick={openDirectRoutingConfigDialog}>
          Administrate
        </Button>
      </div>

      {showConfig &&
        <Grid container sx={{ width: '100%' }}>
          {renderConfigDialog()}
        </Grid>
      }
    </Container>
    </Grid>
  );
}
