import {
  externalAzureFunction,
  externalDIRECTROUTINGFetch,
  externalDIRECTROUTINGStore,
  store,
} from '../utils/httpUtil'

//**GET */
export const api_TPSGetBundles = (params, customerId) =>
  externalDIRECTROUTINGFetch(`bundle/all`, params, customerId)
export const api_TPSGetAltigenVoicePolicies = (customerId,params) =>
  externalDIRECTROUTINGFetch(`NumberAssignment/AltigenVoicePolicies`, params, customerId)

//** POST */

//initial Order
export const createTPSInitialOrderAPI = (companyId, requestBody) =>
  externalDIRECTROUTINGStore(`order`, requestBody, companyId)

//DID Numbers
export const api_TPSNewNumberRequest = (data, customerId) =>
  externalDIRECTROUTINGStore(`NewNumberRequest`, data, customerId)

export const api_TPSDisconnectNumbers = (companyId, requestBody) =>
  externalDIRECTROUTINGStore(`RemoveNumber`, requestBody, companyId)

export const api_AssignVoiceRoutePolicy = (requestBody,companyId) =>
  store(`Customer/configuration/createAndAssignVoiceRoutePolicy`, requestBody, companyId)


//**PUT */

//**DELETE */
