import React, { useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  Dialog,
  DialogContent,
  Typography,
  Tooltip,
  IconButton,
  Box,
} from '@mui/material';
import './DeploymentStatusView.css';
import CancelIcon from '@mui/icons-material/Cancel';
import { setIsDeploymentStatusView } from '../../../../../store/slice/homeSlice';
import { useHomeRedux } from '../../../Home';
import inprogressImage from '../../../../../assets/hourglass-low.png';
import completedImage from '../../../../../assets/clock.png';
import upcomingImage from '../../../../../assets/crystal-ball.png'
import { ORDER_STATUS, DEPLOYMENT_STATUS,DR_SETUP_CONFIG_ID,CARD_HEX_CODE,PRODUCT_CODES } from '../../../../../helpers/Constants';
import { useServiceRedux } from '../../Services';
import { formatDate } from '../../../../../helpers/HelperFunctions';

export default function DeploymentStatusView({ isOpen, setOpenView }) {
  const { dispatch, state: stateHome } = useHomeRedux();
  const { state: serviceState } = useServiceRedux();
  
  const [open, setOpen] = useState(false);
  const [inProgress, setInProgress] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    dispatch(setIsDeploymentStatusView(false));
    setOpenView(false);
  };

  const [deploymentData, setDeploymentData] = useState({
    cardTDR: [],
    carOtherDeployment: [],
  });

  useEffect(() => {
    const orderDeploymentStatusList = stateHome.orderDeploymentStatusList || [];
    const cardTDR = orderDeploymentStatusList.filter(
      (item) => item.revioProductCatalogueId === DR_SETUP_CONFIG_ID
    );
    const carOtherDeployment = orderDeploymentStatusList.filter(
      (item) => item.deploymentStatus !== "" && item.revioProductCatalogueId != DR_SETUP_CONFIG_ID
    );
  
    setDeploymentData({
      cardTDR,
      carOtherDeployment
    });

    setInProgress(cardTDR.some((item) => item.automationDeploymentStatus !== DEPLOYMENT_STATUS.Completed));
  }, [stateHome.orderDeploymentStatusList]);
  let isGrayedOut = false;
  const getCardStyle = (status, isNextCard) => {
    if (isNextCard) {
      isGrayedOut = true;
      return {
        backgroundColor: CARD_HEX_CODE.Upcoming_backgroundColor,
        border: CARD_HEX_CODE.Upcoming_border,
      };
    }

    if(status === DEPLOYMENT_STATUS.Completed){
      return { backgroundColor: CARD_HEX_CODE.Completed_backgroundColor,
          border: CARD_HEX_CODE.Completed_border,}
    }
    else{
      return {
        backgroundColor: CARD_HEX_CODE.InProgress_backgroundColor,
        border: CARD_HEX_CODE.InProgress_border,
      }
    }

  };

  const getStatusImage = (status, isNextCard = false) => {
    if (isNextCard) {
      return { src: upcomingImage, alt: DEPLOYMENT_STATUS.Upcoming };
    }
    return status === DEPLOYMENT_STATUS.Completed
      ? { src: completedImage, alt: DEPLOYMENT_STATUS.Completed }
      : { src: inprogressImage, alt: DEPLOYMENT_STATUS.InProgress };
  };

  const getOrderStatus = () => {
    
    if (
      (deploymentData.cardTDR.length > 0 && deploymentData.cardTDR[0]?.automationDeploymentStatus === DEPLOYMENT_STATUS.Completed) &&
      (deploymentData.carOtherDeployment.length === 0 || (deploymentData.carOtherDeployment.length > 0 && deploymentData.carOtherDeployment[0]?.deploymentStatus === DEPLOYMENT_STATUS.Completed))
    ) {
      return {
        status: DEPLOYMENT_STATUS.Completed,
        description: ORDER_STATUS.Completed
      };
    } else if (
      (deploymentData.cardTDR.length > 0 && deploymentData.cardTDR[0]?.automationDeploymentStatus != null ) || (serviceState.selectedOrderService.productCode !== PRODUCT_CODES.directRouting) &&(
      (deploymentData.carOtherDeployment.length > 0 && deploymentData.carOtherDeployment[0]?.deploymentStatus === DEPLOYMENT_STATUS.InProgress)|| deploymentData.carOtherDeployment.length==0)
    ) {
      return {
        status: DEPLOYMENT_STATUS.Upcoming,
        description: ORDER_STATUS.InProgress
      };
    }else {
      return {
        status: DEPLOYMENT_STATUS.InProgress,
        description: ORDER_STATUS.InProgress
      };
    }
  };
  
  const orderStatus = getOrderStatus();
  const isThirdCardGrayedOut = orderStatus.status === DEPLOYMENT_STATUS.Upcoming;
  const cardNumber = serviceState.selectedOrderService.productCode !== PRODUCT_CODES.directRouting ||deploymentData.carOtherDeployment.length==0 ? "01":"02";
  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        PaperProps={{
          sx: {
            width: '700px',
            borderRadius: '10px',
          },
        }}
        BackdropProps={{
          style: { backgroundColor: 'rgba(0, 0, 0, 0.5)' },
        }}
      >
        <DialogContent sx={{ marginBottom: '40px' }}>
          <Box display="flex" justifyContent="space-between" alignItems="left" mb={2}>
            <Typography variant="subtitle1" component="div" className="provisiontxt" sx={{ fontSize: '30px' }}>
              <div className="cardName">Deployment Status</div>
            </Typography>
            <Tooltip title="Close">
              <IconButton onClick={handleClose}>
                <CancelIcon color="error" size="large" />
              </IconButton>
            </Tooltip>
          </Box>
          {serviceState.selectedOrderService.productCode == PRODUCT_CODES.directRouting?
          <Card className="cardassign" sx={getCardStyle(deploymentData.cardTDR[0]?.automationDeploymentStatus, false)}>
            <CardContent>
              <Typography variant="subtitle1" gutterBottom component="div">
                <div className="cardcontent">
                  <div className="cardleft-section">
                    <div className="cardSno">01</div>
                    <div className="cardName">Teams Provisioning Status</div>
                  </div>
                  <div className="cardright-section">
                    <Box className="cardEstimationTime" sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: '0.5em'
                    }}>
                      {deploymentData && deploymentData.completed?.length > 0 && (
                        <Typography variant='body1'>
                          {deploymentData.completed[0].FSTicketClosedDate
                            ? formatDate(new Date(deploymentData.completed[0].FSTicketClosedDate))
                            : ''}
                        </Typography>
                      )}
                      <img
                        src={getStatusImage(deploymentData.cardTDR[0]?.automationDeploymentStatus,false).src}
                        alt={getStatusImage(deploymentData.cardTDR[0]?.automationDeploymentStatus,false).alt}
                        style={{
                          opacity: '1',
                          maxWidth: '20px',
                          maxHeight: '20px',
                        }}
                      />
                    </Box>
                  </div>
                </div>
              </Typography>
              {deploymentData.cardTDR.length > 0 ? (
                deploymentData.cardTDR.map((deployment, index) => (
                  <Typography key={index} variant="subtitle2" component="div">
                    <div className="cardName">
                      <>{deployment.description}</>
                    </div>
                  </Typography>
                ))
              ) : (
                <Typography variant="subtitle2" component="div">
                  <div className="cardName">
                    <>No Records</>
                  </div>
                </Typography>
              )}
            </CardContent>
          </Card>
          :''}
            <>
            {deploymentData.carOtherDeployment.length > 0 ? 

              <Card className="cardPreference" sx={getCardStyle(deploymentData.carOtherDeployment[0]?.deploymentStatus, inProgress)}>
                <CardContent>
                  <Typography variant="subtitle1" gutterBottom component="div">
                    <div className="cardcontent">
                      <div className="cardleft-section">
                        <div className="cardSno">{cardNumber}</div>
                        <div className="cardName">Packages Provisioning Status</div>
                      </div>
                      <div className="cardright-section">
                        <div className="cardEstimationTime">
                          <img
                            src={getStatusImage(deploymentData.carOtherDeployment[0]?.deploymentStatus,isGrayedOut).src}
                            alt={getStatusImage(deploymentData.carOtherDeployment[0]?.deploymentStatus,isGrayedOut).alt}
                            style={{
                              opacity: '1',
                              maxWidth: '20px',
                              maxHeight: '20px',
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </Typography>
                  {deploymentData.carOtherDeployment.map((deployment, index) => (
                      <Typography key={index} variant="subtitle2" component="div">
                        <div className="cardName">
                          <>{deployment.description}</>
                        </div>
                      </Typography>
                    ))}
                </CardContent>
              </Card> :''}
              <Card className="cardIVR" sx={getCardStyle(orderStatus.status, isThirdCardGrayedOut)}>
                <CardContent>
                  <Typography variant="subtitle1" gutterBottom component="div">
                    <div className="cardcontent">
                      <div className="cardleft-section">
                        <div className="cardSno">{cardNumber=="01"?"02":"03"}</div>
                        <div className="cardName">Order Status</div>
                      </div>
                      <div className="cardright-section">
                        <div className="cardEstimationTime">
                          <img
                            src={getStatusImage(orderStatus.status,isGrayedOut).src}
                            alt={getStatusImage(orderStatus.status,isGrayedOut).alt}
                            style={{
                              opacity: '1',
                              maxWidth: '20px',
                              maxHeight: '20px',
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </Typography>
                  <Typography variant="subtitle2" component="div">
                    <div className="cardName">
                      <>{orderStatus.description}</>
                    </div>
                  </Typography>
                </CardContent>
              </Card>
            </>
        </DialogContent>
      </Dialog>
    </div>
  );
}
