import {
  fetch,
  externalCIFetch,
  externalCIStore,
  externalCACFetch,
  externalCACStore,
  externalCoreViewFetch,
  externalCIUpdate,
  externalCoreViewStore,
  externalCoreViewUpdate,
  externalCoreViewDownload,
  externalCoreViewPatch,
  externalCACUpdate,
  externalCoreViewDelete,
  externalCoreViewDestroyWithBody,
  externalDIRECTROUTINGFetch
} from '../utils/httpUtil'

// Orders  API
export const orderGetAllById = (params) => fetch(`order`, params)

// Azure Users API

// External API CI
export const orderingServiceAzureAdUserCIGet = (params, customerId) =>
  externalCIFetch(`users/AzureAd`, params, customerId)
export const orderingServiceCIFeaturesGet = (params, customerId) =>
  externalCIFetch(`orders/features`, params, customerId)

export const orderingServiceCISummaryGet = (params, customerId) =>
  externalCIFetch(`orders/summary`, params, customerId)

export const orderingServiceCIPost = (data, customerId) =>
  externalCIStore(`orders`, data, customerId)

export const orderingServiceCIDetailsAllAPI = (params, customerId) =>
  externalCIFetch(`orders/all`, params, customerId)

export const orderingServiceCISuspendAPI = (params, customerId) =>
  externalCIUpdate(`orders/suspend`, params, params, customerId)
export const orderingServiceCIReinstateAPI = (params, customerId) =>
  externalCIUpdate(`orders/reinstate`, params, params, customerId)

// External API CAC
export const orderingServiceAzureAdUserCACGet = (params, customerId) =>
  externalCIFetch(`users/AzureAd`, params, customerId)
export const orderingServiceCACFeaturesGet = (params, customerId) =>
  externalCACFetch(`orders/features`, params, customerId)

export const orderingServiceCACSummaryGet = (params, customerId) =>
  externalCACFetch(`orders/summary`, params, customerId)

export const orderingServiceCACPost = (data, customerId) =>
  externalCACStore(`orders`, data, customerId)

export const orderingServiceCACDetailsAllAPI = (params, customerId) =>
  externalCACFetch(`orders/all`, params, customerId)

export const orderingServiceCACSuspendAPI = (params, customerId) =>
  externalCACUpdate(`orders/suspend`, params, params, customerId)
export const orderingServiceCACReinstateAPI = (params, customerId) =>
  externalCACUpdate(`orders/reinstate`, params, params, customerId)

// External API CoreView
export const orderingServiceServiceAreaCode = (params, customerId) =>
  externalCoreViewFetch(`ratecenter/statebyareacode`, params, customerId)
export const orderingServiceRateCenterByState = (params, customerId) =>
  externalCoreViewFetch(`ratecenter`, params, customerId)
export const orderingServiceDIDAvailables = (params, customerId) =>
  externalCoreViewFetch(`DidNumber/available/dids`, params, customerId)
export const orderingServiceDIDNumberGet = (params, customerId) =>
  externalCoreViewFetch(`DidNumber`, params, customerId)
export const orderingServiceTollFreesAvailables = (params, customerId) =>
  externalCoreViewFetch(`DidNumber/available/tollfrees`, params, customerId)
export const orderingServiceCoreViewDIDFeaturesGet = (params, customerId) =>
  externalCoreViewFetch(`DidNumber/features`, params, customerId)
export const orderingServiceCoreViewDIDNumberPatch = (
  body,
  number,
  customerId
) => externalCoreViewPatch(`DidNumber/did?Number=${number}`, body, customerId)
export const orderingServiceCoreViewTollFreeNumberPatch = (
  body,
  number,
  customerId
) =>
  externalCoreViewPatch(`DidNumber/tollfree?Number=${number}`, body, customerId)

export const orderingServiceCoreViewCallForwardBackupRemove = (
  body,
  number,
  customerId
) =>
  externalCoreViewPatch(
    `DidNumber/RemoveCallForwardingOrBackUp?Number=${number}`,
    body,
    customerId
  )

export const orderingServiceCoreViewOrdersGet = (orderId, customerId) =>
  externalCoreViewFetch(`order?Id=${orderId}`, '', customerId)

export const orderingServiceCoreViewFeaturesGet = (params, customerId) =>
  externalCoreViewFetch(`order/features`, params, customerId)

export const orderingServiceCoreViewSummaryGet = (params, customerId) =>
  externalCoreViewFetch(`order/summary`, params, customerId)

export const orderingServiceCoreViewPost = (data, customerId) =>
  externalCoreViewStore(`order`, data, customerId)

export const orderingServiceCoreViewPostSubmit = (data, customerId) =>
  externalCoreViewStore(`order/submit`, data, customerId)
//new
export const createMaxCloudUcOrder = (data, customerId) =>
  externalCoreViewStore(`order`, data, customerId)

export const orderingServiceCoreViewPostTeamsBundle = (data, customerId) =>
  externalCoreViewStore(`TeamsBundleInfo`, data, customerId)
export const orderingServiceTeamsBundleInfoGet = (params, customerId) =>
  externalCoreViewFetch(`TeamsBundleInfo`, params, customerId)
export const orderingServiceTeamsBundleInfoAssignedGet = (params, customerId) =>
  externalCoreViewFetch(`TeamsBundleInfo/configured`, params, customerId)
export const orderingServiceTeamsBundle = (params, customerId) =>
  externalCoreViewFetch(`bundle/all`, params, customerId) //moved

export const orderingServiceTFeatureChangeInfoGet = (params, customerId) =>
  externalCoreViewFetch(`FeatureChangeInfo`, params, customerId)

export const orderingServiceCoreViewNewNumRequest = (data, customerId) =>
  externalCoreViewStore(`NewNumberRequest`, data, customerId) //moved
export const orderingServiceCoreViewNewNumRequestGet = (orderId, customerId) =>
  externalCoreViewFetch(`NewNumberRequest?OrderId=${orderId}`, '', customerId)

export const orderingServiceCoreViewDetailsAllAPI = (params, customerId) =>
  externalCoreViewFetch(`order/all`, params, customerId)

export const orderingServiceCoreViewSuspendAPI = (body, customerId) =>
  externalCoreViewUpdate(`order/suspend`, body, customerId)
export const orderingServiceCoreViewReinStateAPI = (body, customerId) =>
  externalCoreViewUpdate(`order/reinstate`, body, customerId)

export const orderingServiceRestrictionPlan = (params, customerId) =>
  externalCoreViewFetch(`restrictionplan`, params, customerId)
export const orderingServiceRestrictionPlanById = (id, customerId) =>
  externalCoreViewFetch(`restrictionplan/${id}`, customerId)

export const orderingServiceCompanyById = (params, customerId) =>
  externalCoreViewFetch(`company`, params, customerId)

export const orderingServiceE911Validate = (body, customerId) =>
  externalCoreViewStore(`e911/validate`, body, customerId)
export const orderingServiceE911Post = (body, customerId) =>
  externalCoreViewStore(`e911`, body, customerId)
export const orderingServiceE91Update = (body, customerId) =>
  externalCoreViewUpdate(`e911`, body, customerId)

export const orderingServiceRemoveInfoDelete = (body, customerId) =>
  externalCoreViewStore(`RemoveNumber`, body, customerId)
export const orderingServiceRemoveInfoGet = (params, customerId) =>
  externalCoreViewFetch(`RemoveNumber?OrderId=${params}`, '', customerId)

export const orderingServicePortingGetAll = (params, customerId) =>
  externalCoreViewFetch(`porting/all`, params, customerId)
export const orderingServicePortingByDate = (params, customerId) =>
  externalCoreViewFetch(`porting/portdate?Date=${params}`, '', customerId)
export const orderingServicePortingWaitingDetails = (params, customerId) =>
  externalCoreViewFetch(`porting/waiting`, params, customerId)
export const orderingServicePostPortingSubmitWaiting = (data, customerId) =>
  externalCoreViewStore(`porting/submitwaiting`, data, customerId)
export const orderingServiceValidateForPorting = (data, customerId) =>
  externalCoreViewStore(`porting/validateForPorting`, data, customerId)
// did
export const orderingServicePostPortingDid = (data, customerId) =>
  externalCoreViewStore(`porting/did`, data, customerId)
export const orderingServiceCancelPortingDid = (data, customerId) =>
  externalCoreViewStore(`porting/cancel/did`, data, customerId)
export const orderingServiceUploadFormsDid = (data, portingId, customerId) =>
  externalCoreViewStore(
    `porting/uploadforms/did?DidPortingInfoId=${portingId}`,
    data,
    customerId
  )
export const orderingServicePortingDidGetOrderInfo = (params, customerId) =>
  externalCoreViewFetch(`porting/did`, params, customerId)

export const orderingServiceViewPDFLoaDid = (data, customerId) =>
  externalCoreViewDownload(
    `PdfView/Loa/Did?DidPortingInfoId=${data.DidPortingInfoId}`,
    {},
    customerId
  )
export const orderingServiceDownloadPDFDid = (params, customerId) =>
  externalCoreViewDownload(
    `Download/Did?OrderId=${params.OrderId}&FileType=${params.FileType}`,
    '',
    customerId
  )
//toll-free
export const orderingServicePostPortingTollFree = (data, customerId) =>
  externalCoreViewStore(`porting/TollFree`, data, customerId)
export const orderingServiceCancelPortingTollFree = (data, customerId) =>
  externalCoreViewStore(`porting/cancel/TollFree`, data, customerId)
export const orderingServiceUploadFormsTollFree = (
  data,
  portingId,
  customerId
) =>
  externalCoreViewStore(
    `porting/uploadforms/TollFree?TollFreePortingInfoId=${portingId}`,
    data,
    customerId
  )
export const orderingServicePortingTollFreeGetOrderInfo = (
  params,
  customerId
) => externalCoreViewFetch(`porting/TollFree`, params, customerId)

export const orderingServiceViewPDFLoaTollFree = (data, customerId) =>
  externalCoreViewDownload(
    `PdfView/Loa/TollFree?TollFreePortingInfoId=${data.TollFreePortingInfoId}`,
    {},
    customerId
  )
export const orderingServiceDownloadPDFTollFree = (params, customerId) =>
  externalCoreViewDownload(
    `Download/TollFree?OrderId=${params.OrderId}&FileType=${params.FileType}`,
    '',
    customerId
  )

// External API CoreView (MaxFax)
export const createMaxFaxOrderInfo = (customerId, params) =>
  externalCoreViewStore(`MaxFaxOrderInfo`, params, customerId)

export const DeleteMaxFaxOrderInfo = (params) =>
  externalCoreViewDestroyWithBody(`MaxFaxOrderInfo`, params?.CompanyId, params)

//Order Activation 
export const orderActivated = (params) => externalDIRECTROUTINGFetch(`Scheduler/order/activate?ct=${params.ct}&oid=${params.oid}`)

