import React, { useCallback } from 'react';

import {
  Container,
  Typography,
  Button,
  Grid,
} from '@mui/material';
import configAlert from '../../../../assets/config-settings-alert.png';
import DirectRoutingConfigDialog from './DirectRoutingConfigDialog';
import { useState } from 'react'
import { serviceSliceState, setConfiguration } from '../../../../store/slice/serviceSlice';
import { useDispatch, useSelector } from 'react-redux';
import { executeWithTryCatch, gettingCompanyId, isResponseSuccess, normalizeApiResponse } from '../../../../helpers/HelperFunctions';
import { getCustomerConfiguration } from '../../../../api/get/getApi';
import { useToastContext } from '../../../../App';


export default function DirectRoutingConfigurationAlert(props) {
  const [showConfig, setShowConfig] = useState(false);
  const state = useSelector(serviceSliceState);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { toast } = useToastContext();

  const handleConfigChange = () => {
    setShowConfig(false);
    props.onConfigDataChange();
  }

  const openDirectRoutingConfigDialog = () => {
    setShowConfig(true);
    executeWithTryCatch(getConfigurationDetails, handleGetConfigError)
  }

  const getConfigurationDetails = async () => {
    const response = await getCustomerConfiguration(gettingCompanyId());
    if (isResponseSuccess(response)) {
      dispatch(setConfiguration({
        ...response.data.result,
        userName : response.data.result.username,
        aadTenantId : response.data.result.aadTenantId
      }))
    }
    setIsLoading(false)
  }

  const handleGetConfigError = (error) => {
    setIsLoading(false)
    toast.showToast(normalizeApiResponse(error)?.message, 'error');
  }

  const renderConfigDialog = useCallback(() => {
    return (
      <DirectRoutingConfigDialog
        onClose={() => setShowConfig(false)}
        onChange={handleConfigChange}
        isLoading={isLoading}
        goToServiceFunction={props?.goToServiceFunction}
      />
    );
  }, [state.configuration,isLoading]);

  return (
    <Container maxWidth="xl" maxHeight="xl" style={{ textAlign: 'center', padding: '20px' }}>
      <div style={{ marginTop: '5%' }}>
        <img
          src={configAlert}
          alt="Configuration Alert"
          style={{
            maxWidth: '200px',
            maxHeight: '200px',
          }}
        />
      </div>
      <Typography variant="h3" component="h3" style={{ margin: '20px 0' }}>
        Configuration Incomplete
      </Typography>
      <Typography variant="subtitle1" gutterBottom component="div" style={{ marginBottom: '20px' }}>
        Please click the button below to configure your details.
      </Typography>
      <div style={{ justifyContent: 'center', display: 'flex' }}>
        <Button variant="contained" onClick={openDirectRoutingConfigDialog}>
          Administrate
        </Button>
      </div>

      {showConfig &&
        <Grid container sx={{ width: '100%' }}>
          {renderConfigDialog()}
        </Grid>
      }
    </Container>
  );
}
